import { useEffect, useState } from 'react'
import Form, {
  Input,
  Select,
  Textarea
} from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { apiCreate, apiDishesTypes, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import api from 'services/api'
import currency from 'currency.js'
import { genericMaskWithTwoZero } from 'utlis/mask'
import { v4 } from 'uuid'

const BRL = (value: string) =>
  currency(value, {
    decimal: ',',
    symbol: ''
  })

type INutritionalInformation = {
  id: string
  description: string
  quantity: string
  daily_percentage: string
}

type TypesFormProps = {
  initialValues?: IDishData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormDish = ({
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] = useState<IDishData>()
  const [dishesTypes, setDishesTypes] = useState<IDishType[]>([])
  const [dishType, setDishType] = useState<number>()
  const [unitMeasure, setUnitMeasure] = useState<INGREDIENT_TYPES>()
  const [measure, setMeasure] = useState<string>('')
  const [profitInput, setProfit] = useState<string>()
  const [portionInput, setPortion] = useState<string>()
  const [nutritionalInformations, setNutritionalInformations] = useState<
    INutritionalInformation[]
  >([])
  const setNutritionalInformationField = (
    index: number,
    field: keyof INutritionalInformation,
    value: string
  ) => {
    nutritionalInformations[index][field] = value
    setNutritionalInformations([...nutritionalInformations])
  }

  const addNutritionalInformation = () => {
    setNutritionalInformations(prev => [
      ...prev,
      {
        id: v4(),
        daily_percentage: '',
        description: '',
        quantity: ''
      }
    ])
  }

  const removeNutritionalInformation = (index: number) => {
    const copy = [...nutritionalInformations]
    copy.splice(index, 1)
    setNutritionalInformations(copy)
  }

  useEffect(() => {
    api
      .get(apiDishesTypes())
      .then(res =>
        setDishesTypes(
          res.data.sort((a: IDishType, b: IDishType) =>
            a.name.localeCompare(b.name)
          )
        )
      )
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os tipos de pratos',
          description:
            'Houve um erro ao carregar os tipos de pratos disponíveis no banco de dados.'
        })
      })
  }, [addToast])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })

      if (initialValues.profit) {
        setProfit(genericMaskWithTwoZero(initialValues.profit))
      }
      if (initialValues.portion) {
        setPortion(genericMaskWithTwoZero(initialValues.portion))
      }

      if (initialValues.measure) {
        if (['Pedaco', 'Unidade'].includes(initialValues.measure)) {
          setUnitMeasure('unit' as INGREDIENT_TYPES)
          setMeasure(initialValues.measure)
        }
        if (['Litro', 'Mililitro'].includes(initialValues.measure)) {
          setUnitMeasure('volume' as INGREDIENT_TYPES)
          setMeasure(initialValues.measure)
        }
        if (['Kilo', 'Grama', 'Miligrama'].includes(initialValues.measure)) {
          setUnitMeasure('weight' as INGREDIENT_TYPES)
          setMeasure(initialValues.measure)
        }
      }
    }
    if (initialValues?.nutritional_informations) {
      setNutritionalInformations(initialValues.nutritional_informations)
    } else {
      setNutritionalInformations(
        Array.from({ length: 8 }).map(() => ({
          id: v4(),
          daily_percentage: '',
          description: '',
          quantity: ''
        }))
      )
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: IDishData) => {
    for (const item of nutritionalInformations) {
      console.log(item)
      if (item.description && (!item.daily_percentage || !item.quantity)) {
        alert('Quantidade e Porcentagem diária não pode ser 0')
        return
      }
    }

    const id = initialValues?.idUpdate
    data.unit_measure = undefined
    const profit = profitInput ? BRL(profitInput).value : 0
    const portion = portionInput ? BRL(portionInput).value : 0
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data,
            profit,
            portion,
            measure,
            nutritional_informations: nutritionalInformations.filter(
              item => item.description
            )
          }

          activeLoading()
          await api.post(apiCreate(), dataCreate)
          updateDataTable()
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (err: any) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        const dataUpdate = {
          ...data,
          profit,
          portion,
          measure,
          nutritional_informations: nutritionalInformations.filter(
            item => item.description
          )
        }
        try {
          activeLoading()
          const res = await api.put(apiUpdate(String(id)), dataUpdate)
          setDefaultValues(res.data)
          updateDataTable()
          disableLoading()

          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Item atualizado',
            description: 'Item alterado com sucesso'
          })
        } catch (err: any) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o item',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row">
              <Input
                name="name"
                className="col-md-4"
                rules={{ required: true }}
                label="Nome"
              />
              <Input
                name="identifier"
                className="col-md-4"
                rules={{ required: true }}
                label="Identificador"
              />
              <Select
                className=" col-md-2"
                name="dish_type_id"
                label="Tipo"
                onChange={e => setDishType(+e.target.value)}
                options={
                  typeForm === 'update'
                    ? dishesTypes.map(dish_type => {
                        return {
                          name: dish_type.name,
                          value: dish_type.id
                        }
                      })
                    : dishesTypes
                        .filter(dish_type => dish_type.is_active)
                        .map(dish_type => {
                          return {
                            name: dish_type.name,
                            value: dish_type.id
                          }
                        })
                }
                blank
                value={dishType}
                rules={{ required: true }}
                errors={{ dish_type_id: !dishType }}
                defaultValue={''}
              />
              {typeForm === 'update' && (
                <Select
                  className=" col-md-2"
                  name="is_active"
                  label="Ativo"
                  options={[
                    {
                      name: 'Sim',
                      value: 'true'
                    },
                    {
                      name: 'Não',
                      value: 'false'
                    }
                  ]}
                  blank
                  defaultValue={'true'}
                  rules={{ required: true }}
                />
              )}
            </div>
            <div className="row">
              <Input
                name="profit"
                className="col-md-2"
                label="Rendimento"
                price
                onChange={e =>
                  setProfit(genericMaskWithTwoZero(e.target.value))
                }
                onKeyPress={event => {
                  const regex = /^[0-9.]+$/
                  if (!regex.test(event.key)) event.preventDefault()
                }}
              />
              <Input
                name="portion"
                className="col-md-2"
                label="Porção"
                price
                value={portionInput}
                onChange={e =>
                  setPortion(genericMaskWithTwoZero(e.target.value))
                }
                onKeyPress={event => {
                  const regex = /^[0-9.]+$/
                  if (!regex.test(event.key)) event.preventDefault()
                }}
              />
              <Select
                name="unit_measure"
                label="Unidade de medida"
                options={[
                  { name: 'Unidade', value: 'unit' },
                  { name: 'Volume', value: 'volume' },
                  { name: 'Peso', value: 'weight' }
                ]}
                blank
                value={unitMeasure}
                onChange={({ target }) => {
                  setUnitMeasure(target.value as INGREDIENT_TYPES)
                  setMeasure('')
                }}
                defaultValue={''}
                className="col-md-4"
                rules={{ required: true }}
              />

              {unitMeasure &&
                ['weight', 'volume', 'unit'].includes(unitMeasure) && (
                  <Select
                    name="measure"
                    label="Medida"
                    options={
                      unitMeasure === 'weight'
                        ? [
                            { name: 'Kilo', value: 'Kilo' },
                            { name: 'Grama', value: 'Grama' },
                            { name: 'Miligrama', value: 'Miligrama' }
                          ]
                        : unitMeasure === 'volume'
                        ? [
                            { name: 'Litro', value: 'Litro' },
                            { name: 'Mililitro', value: 'Mililitro' }
                          ]
                        : unitMeasure === 'unit'
                        ? [
                            { name: 'Pedaco', value: 'Pedaco' },
                            { name: 'Unidade', value: 'Unidade' }
                          ]
                        : []
                    }
                    blank
                    value={measure}
                    onChange={({ target }) => setMeasure(target.value)}
                    defaultValue={''}
                    className="col-md-4"
                    rules={{ required: true }}
                  />
                )}
            </div>

            <div className="separator my-5" />
            <div className="row">
              <Textarea
                name="description"
                className=" col-md-12"
                label="Descrição"
                style={{ minHeight: 120 }}
                defaultValue=""
                rules={{ required: true, position: 'left' }}
              />
            </div>
            <div className="row mt-4">
              <div className="col-md-2 fs-6form-label fw-bold fs-6 mb-2">
                Descrição
              </div>
              <div className="col-md-2 fs-6form-label fw-bold fs-6 mb-2">
                Quantidade
              </div>
              <div className="col-md-2 fs-6form-label fw-bold fs-6 mb-2">
                Porcentagem diária
              </div>
              {nutritionalInformations.map((item, index) => (
                <div key={item.id} className="row">
                  <Input
                    name={`nutritional_informations.${item.id}.description`}
                    className="col-md-2"
                    value={item.description}
                    onChange={e => {
                      setNutritionalInformationField(
                        index,
                        'description',
                        e.target.value
                      )
                    }}
                    controlled
                  />
                  <Input
                    name={`nutritional_informations.${item.id}.quantity`}
                    className="col-md-2"
                    controlled
                    value={item.quantity}
                    rules={{ required: !!item.description }}
                    onChange={e => {
                      setNutritionalInformationField(
                        index,
                        'quantity',
                        genericMaskWithTwoZero(e.target.value)
                      )
                    }}
                    onKeyPress={event => {
                      const regex = /^[0-9.]+$/
                      if (!regex.test(event.key)) event.preventDefault()
                    }}
                  />
                  <Input
                    name={`nutritional_informations.${item.id}.daily_percentage`}
                    className="col-md-2"
                    controlled
                    value={item.daily_percentage}
                    rules={{ required: !!item.description }}
                    onChange={e => {
                      setNutritionalInformationField(
                        index,
                        'daily_percentage',
                        genericMaskWithTwoZero(e.target.value)
                      )
                    }}
                    onKeyPress={event => {
                      const regex = /^[0-9.]+$/
                      if (!regex.test(event.key)) event.preventDefault()
                    }}
                  />
                  <div className="col-md-1">
                    <button
                      type="button"
                      className="btn btn-warning btn-sm mt-1"
                      onClick={() => removeNutritionalInformation(index)}
                    >
                      Remover
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <button
                type="button"
                className="col-md-2 btn btn-primary btn-sm"
                onClick={() => addNutritionalInformation()}
              >
                + Adicionar
              </button>
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
