import { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions, typesOptions } from '../../domain/info'
import { FormContainer } from './styles'
import axios from 'axios'
import { DateInput } from 'components/Form/date'
import moment from 'moment'

type TypesFormProps = {
  idUpdate?: number
  initialValues?: INoticeData
  typeForm: 'create' | 'update'
}

export const FormNotice = ({
  idUpdate,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] = useState<INoticeData>()

  const [hasUpdated, setHasUpdated] = useState<boolean>(false)

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      setDefaultValues({
        ...initialValues
      })

      if (initialValues.start_date && initialValues.end_date) {
        setStartDate(
          moment(initialValues.start_date, 'DD/MM/YYYY HH:mm:ss').toDate()
        )
        setEndDate(
          moment(initialValues.end_date, 'DD/MM/YYYY HH:mm:ss').toDate()
        )
      }
    }
  }, [initialValues, hasUpdated])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: INoticeData) => {
    const id = idUpdate

    if (!startDate || !endDate) {
      return
    }

    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data,
            start_date: moment(startDate)
              .startOf('day')
              .subtract(3, 'hours')
              .toISOString(),
            end_date: moment(endDate)
              .endOf('day')
              .subtract(3, 'hours')
              .subtract(1, 'minute')
              .toISOString()
          }
          activeLoading()
          await api.post(apiCreate(), dataCreate)
          disableLoading()
          updateDataTable()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (err: any) {
          if (axios.isAxiosError(err)) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description: err.response.data.message
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        const dataUpdate = {
          ...data,
          start_date: moment(startDate)
            .startOf('day')
            .subtract(3, 'hours')
            .toISOString(),
          end_date: moment(endDate)
            .endOf('day')
            .subtract(3, 'hours')
            .subtract(1, 'minute')
            .toISOString()
        }
        try {
          activeLoading()
          const res = await api.put(apiUpdate(String(id)), dataUpdate)
          setDefaultValues(res.data)
          updateDataTable()
          setHasUpdated(true)

          disableLoading()

          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (err: any) {
          console.log(err.response.data)

          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        if (axios.isAxiosError(err)) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description: err.response.data.message
          })
        }
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      defaultValues={{
        ...defaultValues
      }}
    >
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row mb-5">
              <Select
                className="col-3"
                label="Tipo"
                name="type"
                options={typesOptions}
                rules={{ required: true }}
                defaultValue=""
                blank
              />
              <Input
                name="title"
                className="col-md-9"
                label="Título"
                rules={{ required: true }}
              />
            </div>
            <div className="row mb-5">
              <Textarea
                name="description"
                className=" col-md-12"
                label="Descrição"
                rows={3}
                rules={{ required: true }}
              />
            </div>
            <div className="row mb-5">
              <DateInput
                label="Data Inicial"
                name="start_date"
                className=" col-md-4"
                onChange={date => setStartDate(date)}
                selected={startDate}
                minDate={new Date()}
                controlled
              />
              <DateInput
                label="Data Final"
                name="end_date"
                className=" col-md-4"
                onChange={date => setEndDate(date)}
                selected={endDate}
                minDate={new Date()}
                controlled
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
