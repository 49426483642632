import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Form, { Select } from 'components/Form'
import { FilterContainer, TableHeader } from './styles'
import api from 'services/api'
import { apiListInstitution } from '../domain/api'
import { useToast } from 'hooks/toast'
import Container from 'components/Container'
import DataTable from 'components/DataTable'
import { FaDownload } from 'react-icons/fa'
import { useLoading } from 'hooks/loading'

const ContractList = (): JSX.Element => {
  const { addToast } = useToast()
  const [menuFood, setMenuFood] = useState<string>()
  const [institutionOptions, setInstitutionOptions] = useState<
    IInstitutionData[]
  >([])
  const [menuFoodOptions, setMenuFoodOptions] = useState<IMenuFood[]>([])
  const [institution, setInstitution] = useState<string>()
  // PAYAMENT_FORM_PERIODICITIES
  const [parameters, setParameters] = useState<any>()
  const history = useHistory()
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    api
      .get(apiListInstitution())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IInstitutionData, b: IInstitutionData) =>
            a.company_social_name.localeCompare(b.company_social_name)
        )
        setInstitutionOptions(orderedData)
        const menuInstitution = res.data.menu_institution
        setMenuFoodOptions(menuInstitution)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })
  }, [addToast])

  const onSearch = async () => {
    const query = []
    if (menuFood) {
      query.push(`menu_food=${menuFood}`)
    }
    if (institution) {
      query.push(`institution=${institution}`)
    }

    history.replace(`/commercial/contractsMenuFoods?${query.join('&')}`)
    if (menuFood && institution) {
      setParameters([
        {
          menu_food_id: menuFood || undefined,
          institution_id: institution || undefined,
          situation: ['aguardando-pagamento', 'ativo']
        }
      ])
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const menuFood = location.get('menu_food')
    const institution = location.get('institution')

    if (institution || menuFood) {
      if (menuFood) {
        setMenuFood(menuFood)
      }
      if (institution) {
        setInstitution(institution)
      }
      setParameters([
        {
          menu_food_id: menuFood || undefined,
          institution_id: institution || undefined,
          situation: ['aguardando-pagamento', 'ativo']
        }
      ])
    }
  }, [])

  useEffect(() => {
    if (institution && institutionOptions.length) {
      setMenuFood('')
      const selected = institutionOptions
        .find(
          institutionOpt => String(institutionOpt.id) === String(institution)
        )
        ?.menu_institution?.filter(
          menuInstitution => menuInstitution.menu_food.is_active
        )
        ?.map(menuInstitution => menuInstitution.menu_food)
      setMenuFoodOptions(selected)
    }
  }, [institution, institutionOptions])

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <FilterContainer>
        <Form onSubmit={onSearch} className="col-md-12">
          <div className="col-md-12 d-flex">
            <div className="col-md-3 pe-8">
              <Select
                label="Instituição"
                name="institution_id"
                options={institutionOptions?.map(institution => {
                  return {
                    name: institution.company_social_name,
                    value: institution.id
                  }
                })}
                onChange={e => setInstitution(e.target.value)}
                value={institution}
                rules={{ required: true }}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-3 pe-8">
              <Select
                label="Cardápios"
                name="menu_foods"
                options={
                  menuFoodOptions?.map(form_payament => {
                    return {
                      name: form_payament.menu_food_type.name,
                      value: form_payament.id
                    }
                  }) || []
                }
                onChange={e => setMenuFood(e.target.value)}
                value={menuFood}
                rules={{ required: true }}
                blank
                fullControlled
                defaultValue={''}
              />
            </div>
          </div>
          <footer className="col-md-12 d-flex justify-content-end pb-5 gap-5">
            <button
              type="reset"
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setInstitution(undefined)
                setMenuFood(undefined)
              }}
            >
              LIMPAR
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              BUSCAR
            </button>
          </footer>
        </Form>
        <hr />
      </FilterContainer>
      {parameters && (
        <>
          <TableHeader>
            <h3 className="h3 pt-6">Listagem</h3>
            <button
              type="button"
              className="btn"
              onClick={async () => {
                activeLoading()
                api
                  .post(
                    'commercial/contractsMenuFoods/reportPdf',
                    {
                      menu_food_id: parameters[0].menu_food_id,
                      institution_id: parameters[0].institution_id,
                      situation: parameters[0].situation
                    },
                    {
                      responseType: 'arraybuffer'
                    }
                  )
                  .then(res => {
                    const a = document.createElement('a')
                    const blob = new Blob([res.data], {
                      type: 'application/pdf'
                    })
                    const url = window.URL.createObjectURL(blob)
                    a.href = url
                    const institutionName = institutionOptions.find(
                      a => a.id === Number(parameters[0].institution_id)
                    )?.company_social_name
                    const date = new Date()
                    const today = `${date.getDate()}-${String(
                      date.getMonth() + 1
                    ).padStart(2, '0')}-${date.getFullYear()}`
                    const fileName = `Relatório - ${institutionName} ${today}.pdf`
                    a.download = fileName
                    a.click()
                    disableLoading()
                  })
                  .catch(() => {
                    disableLoading()
                  })
              }}
            >
              <FaDownload />
              Baixar relatório
            </button>
          </TableHeader>
          <DataTable
            source={nameSource}
            entity={nameEntity}
            format={{ orderBy: 'id' }}
            headers={headers}
            searchParameters={parameters}
          />
        </>
      )}
    </Container>
  )
}
export default ContractList
