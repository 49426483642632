/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Container from '../../../../../components/Container'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { nameActions } from '../domain/info'
import {
  apiDelete,
  apiDeleteDishesImages,
  apiDeleteDishesIngredients,
  apiList
} from '../domain/api'
import { apiList as apiListIngredients } from '../../Ingredients/domain/api'
import { breadcrumbView } from '../domain/breadcrumb'

import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import DataTable from 'components/DataTable'
import { headersImages, headersIngredients } from '../domain/headers'
import Modal from 'components/Modal'
import { FormAddIngredient } from '../components/FormAddIngredient'
import { useUpdateDataTable } from 'hooks/dataTable'
import { INGREDIENT_TYPES_MAP } from '../../Ingredients/domain/info'
import { FormAddImage } from '../components/FormAddImage'

const DishView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [dish, setDish] = useState<IDishData | null>(null)
  const { addToast } = useToast()
  const { disableLoading, activeLoading } = useLoading()
  const [ingredients, setIngredients] = useState<IIngredient[]>([])

  const loadIngredients = async () => {
    const { data } = await api.get(apiListIngredients())
    setIngredients(data)
  }

  useEffect(() => {
    loadIngredients()
  }, [])

  const searchParametersAuditLog = useRef([{ entity: 'Dish', entity_id: id }])
  const searchParametersIngredient = useRef([{ dish_id: id }])
  const searchParametersImage = useRef([{ dish_id: id }])

  const loadDish = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<IDishData>(apiList(id))
      const { data } = response
      setDish(data)
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o item',
        description:
          'Houve um error ao carregar o item, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading, id])

  const [modalAddIngredients, setModalAddIngredients] = useState({
    open: false,
    dish_id: 0
  })

  const [modalEditIngredients, setModalEditIngredients] = useState<{
    open: boolean
    dish?: IDishIngredient
  }>({
    open: false
  })
  const handleClickOnOpenModalAddIngredient = (id: number) => {
    setModalAddIngredients({
      open: true,
      dish_id: id
    })
  }
  const handleClickOnCloseModalAddIngredient = () => {
    updateDataTable()
    setModalAddIngredients({
      open: false,
      dish_id: 0
    })
  }
  const handleClickOnCloseModalEditIngredient = () => {
    updateDataTable()
    setModalEditIngredients({
      open: false
    })
  }

  const [modalAddImages, setModalAddImages] = useState({
    open: false,
    dish_id: 0
  })
  const [modalEditImages, setModalEditImages] = useState<{
    open: boolean
    dish?: IDishImage
  }>({
    open: false
  })
  const handleClickOnOpenModalAddImage = (id: number) => {
    setModalAddImages({
      open: true,
      dish_id: id
    })
  }
  const handleClickOnCloseModalAddImage = () => {
    updateDataTable()
    setModalAddImages({
      open: false,
      dish_id: 0
    })
  }
  const handleClickOnCloseModalEditImage = () => {
    updateDataTable()
    setModalEditImages({
      open: false
    })
  }

  useEffect(() => {
    loadDish()
  }, [loadDish])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)
  const [alertRemoveDishIngredient, setAlertRemoveDishIngredient] = useState({
    open: false,
    id: 0
  })
  const [alertRemoveDishImage, setAlertRemoveDishImage] = useState({
    open: false,
    id: 0
  })

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Item removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title:
          'Não é possível remover esse item, pois ele está registrado num menu.'
      })
    }
  }

  const handlerOnClickButtonConfirmRemoveDishIngredient = async (
    id: number
  ) => {
    try {
      await api.delete(apiDeleteDishesIngredients(String(id)))
      setAlertRemoveDishIngredient({
        open: false,
        id: 0
      })
      addToast({
        type: 'success',
        title: 'Item removido com sucesso.'
      })
      updateDataTable()
    } catch (err: any) {
      setAlertRemoveDishIngredient({
        open: false,
        id: 0
      })
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title:
          'Não é possível remover esse item, pois ele está registrado num menu.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  const handlerOnClickButtonCancelRemoveDishIngredient = () => {
    setAlertRemoveDishIngredient({
      open: false,
      id: 0
    })
  }

  const handlerOnClickButtonConfirmRemoveDishImage = async (id: number) => {
    try {
      await api.delete(apiDeleteDishesImages(String(id)))
      setAlertRemoveDishImage({
        open: false,
        id: 0
      })
      addToast({
        type: 'success',
        title: 'Item removido com sucesso.'
      })
      updateDataTable()
    } catch (err: any) {
      setAlertRemoveDishImage({
        open: false,
        id: 0
      })
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title:
          'Não é possível remover esse item, pois ele está registrado num menu.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveDishImage = () => {
    setAlertRemoveDishImage({
      open: false,
      id: 0
    })
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Cód
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{dish?.id}</p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Tipo
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {dish?.dish_type.name}
              </p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Nome
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{dish?.name}</p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Ativo
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {dish?.is_active ? 'Sim' : 'Não'}
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Rendimento
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{dish?.profit}</p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Porção
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{dish?.portion}</p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <label htmlFor="name" className="col-form-label">
                Descrição
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {dish?.description}
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <label className="col-form-label">Informaões nutricionais</label>
              {dish?.nutritional_informations && (
                <div className="row">
                  <div className="col-md-2 fs-6form-label fw-bold fs-6 mb-2">
                    Descrição
                  </div>
                  <div className="col-md-2 fs-6form-label fw-bold fs-6 mb-2">
                    Quantidade
                  </div>
                  <div className="col-md-2 fs-6form-label fw-bold fs-6 mb-2">
                    Porcentagem diária
                  </div>
                </div>
              )}
              {dish?.nutritional_informations?.map(item => (
                <div key={item.id} className="row">
                  <div className="col-md-2">{item.description}</div>
                  <div className="col-md-2">{item.quantity}</div>
                  <div className="col-md-2">{item.daily_percentage}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <label htmlFor="name" className="col-form-label">
                Cadastrado em:
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{dish?.created_at}</p>
            </div>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="row">
          <div className="col-md-12">
            <Tabs>
              <Tab title="Ingredientes" key={0}>
                <div className="portlet light">
                  <div
                    className="portlet-title mt-3 d-flex justify-content-between"
                    style={{ height: '34.4px' }}
                  >
                    <div className="caption fs-5 fw-bold">Listagem</div>
                    <div className="">
                      <a
                        href="#"
                        onClick={() =>
                          handleClickOnOpenModalAddIngredient(Number(id))
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="fa fa-plus" /> Adicionar
                      </a>
                    </div>
                  </div>
                  <div className="portlet-body form">
                    <DataTable
                      format={{ orderBy: 'id' }}
                      source="dishesIngredients"
                      entity="DishIngredient"
                      headers={headersIngredients}
                      searchParameters={searchParametersIngredient.current}
                      customHeaders={[
                        {
                          field: 'ingredient.unit_measure',
                          name: 'Unidade de medida',
                          sortable: false,
                          element: item => (
                            <span>
                              {
                                INGREDIENT_TYPES_MAP[
                                  item.ingredient
                                    .unit_measure as INGREDIENT_TYPES
                                ]
                              }
                            </span>
                          )
                        },
                        {
                          name: 'Medida',
                          field: 'measure',
                          sortable: true,
                          element: item => (
                            <span>
                              {item.measure ||
                                INGREDIENT_TYPES_MAP[
                                  item.ingredient
                                    .unit_measure as INGREDIENT_TYPES
                                ]}
                            </span>
                          )
                        }
                      ]}
                      actions={[
                        {
                          name: 'Update',
                          title: 'Atualizar',
                          spanIcon: 'fa fa-edit',
                          onClick: item => {
                            setModalEditIngredients({
                              open: true,
                              dish: item
                            })
                          }
                        },
                        {
                          name: 'Delete',
                          title: 'Deletar',
                          spanIcon: 'fa fa-trash',
                          style: { paddingLeft: '5px' },
                          onClick: item => {
                            setAlertRemoveDishIngredient({
                              open: true,
                              id: Number(item.id)
                            })
                          }
                        }
                      ]}
                    />
                  </div>
                </div>
              </Tab>
              <Tab title="Imagens" key={1}>
                <div className="portlet light">
                  <div
                    className="portlet-title mt-3 d-flex justify-content-between"
                    style={{ height: '34.4px' }}
                  >
                    <div className="caption fs-5 fw-bold">Listagem</div>
                    <div className="">
                      <a
                        href="#"
                        onClick={() =>
                          handleClickOnOpenModalAddImage(Number(id))
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="fa fa-plus" /> Adicionar
                      </a>
                    </div>
                  </div>
                  <div className="portlet-body form">
                    <DataTable
                      format={{ orderBy: 'id' }}
                      source="dishesImages"
                      entity="DishImage"
                      headers={headersImages}
                      searchParameters={searchParametersImage.current}
                      customHeaders={[
                        {
                          field: 'cover',
                          name: 'Capa',
                          sortable: false,
                          element: item => (
                            <span>{item.cover ? 'SIM' : 'NÃO'}</span>
                          )
                        }
                      ]}
                      actions={[
                        {
                          name: 'Update',
                          title: 'Atualizar',
                          spanIcon: 'fa fa-edit',
                          onClick: item => {
                            setModalEditImages({
                              open: true,
                              dish: item
                            })
                          }
                        },
                        {
                          name: 'Delete',
                          title: 'Deletar',
                          spanIcon: 'fa fa-trash',
                          style: { paddingLeft: '5px' },
                          onClick: item => {
                            setAlertRemoveDishImage({
                              open: true,
                              id: Number(item.id)
                            })
                          }
                        }
                      ]}
                    />
                  </div>
                </div>
              </Tab>
              <Tab title="Histórico" key={2}>
                <div className="portlet light">
                  <div
                    className="portlet-title mt-3 d-flex justify-content-between"
                    style={{ height: '34.4px' }}
                  >
                    <div className="caption fs-5 fw-bold">Listagem</div>
                    <div className="tools"></div>
                  </div>
                  <div className="portlet-body form">
                    <DataTable
                      entityId={id}
                      format={{ orderBy: '' }}
                      source="auditLogs"
                      entity="AuditLog"
                      searchParameters={searchParametersAuditLog.current}
                    />
                  </div>
                </div>
              </Tab>
              <></>
            </Tabs>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${dish?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(dish?.id))
        }
        isActive={alertRemoveParent}
      />
      <Alert
        message={'Tem certeza que deseja excluir o registro?'}
        onClickCancelButton={handlerOnClickButtonCancelRemoveDishIngredient}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveDishIngredient(
            alertRemoveDishIngredient.id
          )
        }
        isActive={alertRemoveDishIngredient.open}
      />
      <Alert
        message={'Tem certeza que deseja excluir o registro?'}
        onClickCancelButton={handlerOnClickButtonCancelRemoveDishImage}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveDishImage(alertRemoveDishImage.id)
        }
        isActive={alertRemoveDishImage.open}
      />
      {modalAddIngredients && (
        <Modal
          pageTitle={'Adicionar ingrediente'}
          onClickButtonCancel={handleClickOnCloseModalAddIngredient}
          isOpenModal={modalAddIngredients.open}
          style={{
            padding: '10px'
          }}
          Children={
            <>
              <FormAddIngredient
                handleClickOnCloseModal={handleClickOnCloseModalAddIngredient}
                typeForm="create"
                initialValues={{
                  dish_id: modalAddIngredients.dish_id
                }}
                ingredients={ingredients}
              />
            </>
          }
        />
      )}
      {modalEditIngredients && (
        <Modal
          pageTitle={'Editar ingrediente'}
          onClickButtonCancel={handleClickOnCloseModalEditIngredient}
          isOpenModal={modalEditIngredients.open}
          style={{
            padding: '10px'
          }}
          Children={
            <>
              <FormAddIngredient
                handleClickOnCloseModal={handleClickOnCloseModalEditIngredient}
                typeForm="update"
                initialValues={{
                  dish_id: Number(id),
                  dish_ingredient: modalEditIngredients.dish
                }}
                ingredients={ingredients}
              />
            </>
          }
        />
      )}
      {modalAddImages && (
        <Modal
          pageTitle={'Adicionar imagem'}
          onClickButtonCancel={handleClickOnCloseModalAddImage}
          isOpenModal={modalAddImages.open}
          style={{
            padding: '10px'
          }}
          Children={
            <>
              <FormAddImage
                handleClickOnCloseModal={handleClickOnCloseModalAddImage}
                typeForm="create"
                initialValues={{
                  dish_id: modalAddImages.dish_id
                }}
              />
            </>
          }
        />
      )}
      {modalEditImages && (
        <Modal
          pageTitle={'Editar imagem'}
          onClickButtonCancel={handleClickOnCloseModalEditImage}
          isOpenModal={modalEditImages.open}
          style={{
            padding: '10px'
          }}
          Children={
            <>
              <FormAddImage
                handleClickOnCloseModal={handleClickOnCloseModalEditImage}
                typeForm="update"
                initialValues={{
                  dish_id: Number(id),
                  dish_image: modalEditImages.dish
                }}
              />
            </>
          }
        />
      )}
    </>
  )
}

export default DishView
