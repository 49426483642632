import { nameEntity, nameSource } from '../domain/info'
import { headersDaily } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Form, { Select } from 'components/Form'
import { DateInput } from 'components/Form/date'
import { FilterContainer, TableHeader } from './styles'
import api from 'services/api'
import { apiListInstitution } from '../domain/api'
import { useToast } from 'hooks/toast'
import Container from 'components/Container'
import DataTable from 'components/DataTable'
import { FaDownload, FaTag } from 'react-icons/fa'
import { useLoading } from 'hooks/loading'
import moment from 'moment'
import Modal from 'components/Modal'
import { InstitutionsForm } from '../components/InstitutionsForm'

const ContractList = (): JSX.Element => {
  const { addToast } = useToast()
  const [menuFood, setMenuFood] = useState<string>()
  const [date, setDate] = useState<Date>()
  const [institutionOptions, setInstitutionOptions] = useState<
    IInstitutionData[]
  >([])
  const [menuFoodOptions, setMenuFoodOptions] = useState<IMenuFood[]>([])
  const [institution, setInstitution] = useState<string>()
  // PAYAMENT_FORM_PERIODICITIES
  const [parameters, setParameters] = useState<any>()
  const history = useHistory()

  const [modalLabel, setModalLabel] = useState<{
    open: boolean
    menu_food_id?: string
    institution_id?: any
    situation?: string[]
    date?: string
  }>({ open: false })

  const handleClickOnCloseModal = (): void => {
    setModalLabel({
      open: false
    })
  }

  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    api
      .get(apiListInstitution())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IInstitutionData, b: IInstitutionData) =>
            a.company_social_name.localeCompare(b.company_social_name)
        )
        setInstitutionOptions(orderedData)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })
  }, [addToast])

  const onSearch = async () => {
    const query = []
    if (menuFood) {
      query.push(`menu_food=${menuFood}`)
    }
    if (institution) {
      query.push(`institution=${institution}`)
    }
    if (date) {
      query.push(`date=${moment(date).format('YYYY-MM-DD')}`)
    }

    history.replace(`/commercial/contractsMenuFoods/daily?${query.join('&')}`)
    if (menuFood && institution) {
      setParameters([
        {
          menu_food_id: menuFood || undefined,
          institution_id: institution || undefined,
          situation: ['aguardando-pagamento', 'ativo'],
          date: moment(date).format('YYYY-MM-DD')
        }
      ])
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const menuFood = location.get('menu_food')
    const institution = location.get('institution')
    const date = location.get('date')

    if (institution && menuFood && date) {
      setMenuFood(menuFood)
      setInstitution(institution)
      setDate(moment(date, 'YYYY-MM-DD').toDate())
      setParameters([
        {
          menu_food_id: menuFood || undefined,
          institution_id: institution || undefined,
          situation: ['aguardando-pagamento', 'ativo'],
          date
        }
      ])
    }
  }, [])

  useEffect(() => {
    if (institution && institutionOptions.length) {
      setMenuFood('')
      const selected = institutionOptions
        .find(
          institutionOpt => String(institutionOpt.id) === String(institution)
        )
        ?.menu_institution?.filter(
          menuInstitution => menuInstitution.menu_food.is_active
        )
        ?.map(menuInstitution => menuInstitution.menu_food)
      setMenuFoodOptions(selected)
    }
  }, [institution, institutionOptions])

  const handleError = useCallback(() => {
    setParameters(undefined)
    addToast({
      type: 'error',
      title: 'Sem itens para este cardápio'
    })
  }, [addToast])

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <FilterContainer>
        <Form onSubmit={onSearch} className="col-md-12">
          <div className="col-md-12 d-flex">
            <div className="col-md-3 pe-8">
              <Select
                label="Instituição"
                name="institution_id"
                options={institutionOptions?.map(institution => {
                  return {
                    name: institution.company_social_name,
                    value: institution.id
                  }
                })}
                onChange={e => setInstitution(e.target.value)}
                value={institution}
                rules={{ required: true }}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-3 pe-8">
              <Select
                label="Cardápios"
                name="menu_foods"
                options={
                  menuFoodOptions?.map(form_payament => {
                    return {
                      name: form_payament.menu_food_type.name,
                      value: form_payament.id
                    }
                  }) || []
                }
                onChange={e => setMenuFood(e.target.value)}
                value={menuFood}
                rules={{ required: true }}
                blank
                fullControlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-2 pe-8">
              <DateInput
                label="Data inicial"
                name="start_validity"
                onChange={date => setDate(date)}
                selected={date}
                controlled
              />
            </div>
          </div>
          <footer className="col-md-12 d-flex justify-content-end pb-5 gap-5">
            <button
              type="reset"
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setInstitution(undefined)
                setMenuFood(undefined)
                setDate(undefined)
              }}
            >
              LIMPAR
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              BUSCAR
            </button>
          </footer>
        </Form>
        <hr />
      </FilterContainer>
      {parameters && (
        <>
          <TableHeader>
            <h3 className="h3 pt-6">Listagem</h3>
            <div>
              <button
                type="button"
                className="btn"
                onClick={async () => {
                  activeLoading()
                  api
                    .post(
                      'commercial/contractsMenuFoods/reportPdf',
                      {
                        menu_food_id: parameters[0].menu_food_id,
                        institution_id: parameters[0].institution_id,
                        situation: parameters[0].situation,
                        date: moment(date).format('YYYY-MM-DD')
                      },
                      {
                        responseType: 'arraybuffer'
                      }
                    )
                    .then(res => {
                      const a = document.createElement('a')
                      const blob = new Blob([res.data], {
                        type: 'application/pdf'
                      })
                      const url = window.URL.createObjectURL(blob)
                      a.href = url
                      const institutionName = institutionOptions.find(
                        a => a.id === Number(parameters[0].institution_id)
                      )?.company_social_name
                      const date = new Date()
                      const today = `${date.getDate()}-${String(
                        date.getMonth() + 1
                      ).padStart(2, '0')}-${date.getFullYear()}`
                      const fileName = `Relatório - ${institutionName} ${today}.pdf`
                      a.download = fileName
                      a.click()
                      disableLoading()
                    })
                    .catch(() => {
                      disableLoading()
                    })
                }}
              >
                <FaDownload />
                Baixar relatório
              </button>
              <button
                type="button"
                className="btn"
                onClick={async () => {
                  setModalLabel({
                    open: true,
                    menu_food_id: parameters[0].menu_food_id,
                    institution_id: parameters[0].institution_id,
                    situation: parameters[0].situation,
                    date: moment(date).format('YYYY-MM-DD')
                  })
                }}
              >
                <FaTag />
                Baixar etiquetas
              </button>
            </div>
          </TableHeader>
          <DataTable
            source={nameSource}
            entity={nameEntity}
            format={{ orderBy: 'id' }}
            headers={headersDaily}
            searchParameters={parameters}
            handleError={handleError}
          />
        </>
      )}
      {modalLabel && (
        <Modal
          onClickButtonCancel={handleClickOnCloseModal}
          isOpenModal={modalLabel.open}
          pageTitle={'Escolha as instituições'}
          Children={
            <InstitutionsForm
              menu_food_id={modalLabel.menu_food_id}
              institution_id={modalLabel.institution_id}
              situation={modalLabel.situation}
              handleClickOnCloseModal={handleClickOnCloseModal}
              date={modalLabel.date}
              institutionOptions={institutionOptions}
            />
          }
        />
      )}
    </Container>
  )
}
export default ContractList
