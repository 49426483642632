import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../components/Container'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { apiList } from '../domain/api'
import { nameActions } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useToast } from '../../../../hooks/toast'
import { FormNotice } from '../components/Form'

const NoticeUpdate = (): JSX.Element => {
  const { addToast } = useToast()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [noticeItem, setnoticeItem] = useState<INoticeData>()

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setnoticeItem(data)
        disableLoading()
      } catch (err) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o aviso',
          description:
            'Houve um error ao carregar o aviso, tente novamente mais tarde!'
        })
      }
    }
    loadData()
  }, [activeLoading, addToast, disableLoading, id])

  return (
    <Container
      pageTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={toolsUpdate}
    >
      <FormNotice
        typeForm="update"
        idUpdate={noticeItem?.id}
        initialValues={{
          id: noticeItem?.id,
          ...noticeItem
        }}
      />
    </Container>
  )
}

export default NoticeUpdate
