type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'Notice'
const nameSource = 'commercial/notices'
const namePageTitle = 'Avisos'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Avisos',
  to: '/commercial/notices',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/commercial/notices/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/notices/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Editar',
    to: '/commercial/notices/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

const typesOptions = [
  { name: 'Dica', value: 'tip' },
  { name: 'Informação', value: 'information' },
  { name: 'Promoção', value: 'promotion' },
  { name: 'Importante', value: 'important' }
]

const typesMap = {
  tip: 'Dica',
  information: 'Informação',
  promotion: 'Promoção',
  important: 'Importante'
}

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions,
  typesOptions,
  typesMap
}
