import NoticesList from '../../pages/Commercial/Notices/List'
import NoticesUpdate from '../../pages/Commercial/Notices/Update'
import NoticesCreate from '../../pages/Commercial/Notices/Create'
import NoticesView from '../../pages/Commercial/Notices/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const NoticesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/notices" exact component={NoticesList} />
    <Route path="/commercial/notices/create" component={NoticesCreate} exact />
    <Route path="/commercial/notices/view/:id" component={NoticesView} exact />
    <Route
      path="/commercial/notices/update/:id"
      component={NoticesUpdate}
      exact
    />
  </CustomSwitch>
)
