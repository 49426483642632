import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource, typesMap } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'

const NoticeList = (): JSX.Element => {
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'id' }}
        headers={headers}
        customHeaders={[
          {
            field: 'type',
            name: 'Tipo',
            sortable: false,
            element: item => <p>{typesMap[item.type as 'tip']}</p>
          }
        ]}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: notice => `/commercial/notices/view/${notice.id}`,
            style: { margin: '0 5px' }
          },
          {
            name: 'Editar',
            title: 'Editar',
            spanIcon: 'fa fa-edit',
            linkTo: notice => `/commercial/notices/update/${notice.id}`
          }
        ]}
      />
    </Container>
  )
}

export default NoticeList
