import { ChangeEvent, useEffect, useRef, useState } from 'react'
import Form, { Select } from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { apiCreateDishesImages, apiUpdateDishesImages } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import api from 'services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

type TypesFormProps = {
  handleClickOnCloseModal: () => void
  initialValues?: {
    dish_id: number
    dish_image?: IDishImage
  }
  typeForm: 'create' | 'update'
}

export const FormAddImage = ({
  handleClickOnCloseModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { activeLoading, disableLoading } = useLoading()

  const [defaultValues, setDefaultValues] = useState<IDishImage>()

  const [file, setFile] = useState<File>()
  const fileRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (initialValues.dish_image) {
      setDefaultValues({
        ...initialValues.dish_image,
        cover: initialValues.dish_image.cover ? 'true' : 'false'
      })
    }
  }, [initialValues.dish_image])

  const onSubmitForm = async (data: any) => {
    if (!file && typeForm === 'create') return

    const dataUpload = new FormData()

    if (file) dataUpload.append('file', file)
    dataUpload.append('dish_id', String(initialValues.dish_id))
    dataUpload.append('cover', data.cover)

    try {
      if (typeForm === 'create') {
        try {
          activeLoading()
          await api.post(apiCreateDishesImages(), dataUpload)
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          handleClickOnCloseModal()
        } catch (err: any) {
          let message =
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'

          if (err?.response?.data?.message === 'File too large') {
            message = 'Arquivo deve ter até 1mb.'
          }
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description: message
          })
          disableLoading()
        }
      } else {
        try {
          if (!initialValues.dish_image) {
            return
          }
          const id = initialValues.dish_image.id
          activeLoading()
          console.log(data)
          await api.put(apiUpdateDishesImages(String(id)), {
            cover: data.cover
          })
          disableLoading()
          addToast({
            type: 'success',
            title: 'Item atualizado',
            description: 'Item alterado com sucesso'
          })
          handleClickOnCloseModal()
        } catch (err: any) {
          history.push(nameActions.read.to)
          console.log(err.response.data, 'here')

          addToast({
            type: 'error',
            title: 'Erro ao atualizar o item',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]
    setFile(file)
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row">
              {typeForm === 'create' && (
                <div className="col-md-6">
                  <label htmlFor="file" className="col-form-label fw-bold fs-6">
                    <FontAwesomeIcon icon={faCamera} />
                    <span> Selecionar imagem </span>
                  </label>

                  <input
                    style={{ display: 'none' }}
                    type="file"
                    name="file"
                    id="file"
                    ref={fileRef}
                    onChange={onChangeFileHandler}
                  />
                  {file && <p>Imagem selecionada: {file.name}</p>}
                </div>
              )}

              <Select
                name="cover"
                label="Imagem da capa?"
                options={[
                  {
                    name: 'SIM',
                    value: 'true'
                  },
                  {
                    name: 'NÃO',
                    value: 'false'
                  }
                ]}
                blank
                defaultValue={'false'}
                className="col-md-4"
                rules={{ required: true }}
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
